import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import MadDog1 from "../../components/MadDog1"

const Maddog = () => (
  <Layout>
    <SEO title="HaroldRichter.art" />
    <MadDog1/>
  </Layout>
)

export default Maddog
